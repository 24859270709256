import React, { useState, useEffect, useContext } from "react";
import { Table } from "antd";
import ServerData from "../utils/ServerData";
import DataParser from "../utils/DataParser";
import { history } from "../history/index";

import ReactLoading from "react-loading";
import config from "../env-config.json";

import "../styles/global.css";
import "../styles/transaction.css";
import { AuthContext } from "../state";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "Sender Name",
    dataIndex: "sender",
    key: "sender"
  },
  {
    title: "Beneficiary Name",
    dataIndex: "beneficiary_name",
    key: "beneficiary_name"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status"
  },
  {
    title: "Status Date",
    dataIndex: "status_date",
    key: "status_date"
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount"
  }
];

function TransactionList(props) {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     transactions: [],
  //     included: [],
  //     transaction: null,
  //     loading: true,
  //     loaderType: "bars",
  //     loaderColor: "#0b5ca9",
  //     errorMessage: "",
  //     hasError: false
  //   };

  //   // check if the user is a manager or an agent
  //   // if agent redirect to search transaction page
  //     if(localStorage.getItem("isManager") !== "isManager") {
  //       history.push({
  //         pathname: "/search",
  //         state: {
  //             //message : message
  //         }
  //     });
  //   }
  // }

  const [transactions, setTransactions] = useState([]);
  const [included, setIncluded] = useState([]);
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaderType, setLoaderType] = useState('bars');
  const [loaderColor, setLoaderColor] = useState('#0b5ca9');
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { state } = useContext(AuthContext);

  // construct life cycle hook
  useEffect(() => {
    // check if the user is a manager or an agent
    // if agent redirect to search transaction page
    // if (localStorage.getItem("isManager") !== "isManager") {
    if (!state.isManager) {
      history.push({
        pathname: "/search",
        state: {
          //message : message
        }
      });
    }
  }, []);


  // component did mount life cycle hook
  useEffect(() => {
    // this.requestTransactions();
    requestTransactions();
  }, []);

  const requestTransactions = () => {
    fetch(
      config.api_url +
      "transactions/?include=currency,sender,sender.country,beneficiary,beneficiary.country&page_size=5",
      // ServerData.getAuthHeaders()
      {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'Accept': 'application/vnd.api+json',
          'Authorization': 'Bearer ' + state.accessToken,
          'App-Id': config.app_id,
        }
      }
    )
      .then(res => res.json())
      .then(result => {
        console.log(JSON.stringify(result));
        // this.setState({
        //   loading: false,
        //   transactions: result.data,
        //   included: result.included
        // });
        setLoading(false);
        setTransactions(result.data);
        setIncluded(result.included);
      })
      .catch(err => {
        // this.setState({
        //   loading: false,
        //   hasError: true,
        //   errorMessage: "Problem connecting to the server. Try again."
        // });
        setLoading(false);
        setHasError(true);
        setErrorMessage('Problem connecting to the server. Try again.');
        console.log("Fetch Error :-S", err);
      });
  }



  const loadTransaction = transaction => {
    history.push({
      pathname: "/transaction/" + transaction.id,
      state: {
        transaction: transaction
      }
    });
  };

  const renderTable = () => {
    // console.log("the transactions", this.state.transactions)
    console.log("the transactions", transactions)
    // if (!this.state.loading && (this.state.transactions === undefined)) {
    if (!loading && (transactions === undefined)) {
      return (
        <h2>No Transactions</h2>
      );
      // } else if(!this.state.loading) {
    } else if (!loading) {
      return (
        <Table
          rowclassNameName={(record, index) =>
            index % 2 === 0 ? "" : "table-even-row"
          }
          size="middle"
          pagination={{ hideOnSinglePage: true }}
          className="table table-hover table-striped"
          bordered
          rowKey="id"
          dataSource={DataParser.getTransactions(
            // this.state.transactions,
            transactions,
            // this.state.included
            included
          )}
          columns={columns}
          onRow={transaction => {
            return {
              onClick: () => {
                // this.loadTransaction(transaction);
                loadTransaction(transaction);
              }
            };
          }}
        />
      );
    }
  }

  const renderFetching = () => {
    // const { loaderType, loaderColor } = this.state;
    // if (this.state.loading) {
    if (loading) {
      return (
        <div className="col-6 offset-sm-3 text-center">
          <p> Fetching your authorized transactions. Please wait...</p>
          <ReactLoading
            className="searching--spinner--100"
            type={loaderType}
            color={loaderColor}
          />
        </div>
      );
    }
  }

  const renderErrorMessage = () => {
    // const { errorMessage, hasError, submitted } = this.state;
    if (!submitted && hasError) {
      return (
        <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          <strong>Error: </strong> {errorMessage}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  }

  return (
    <div className="our-causes pt-0">
      <div className="my-content">
        <div className="row">
          <div className="col-12 ">
            <div className="cause-wrap">
              <div className="cause-content-wrap">
                <div className="entry-content">
                  {/* {this.renderFetching()} */}
                  {renderFetching()}
                  {/* {this.renderTable()} */}
                  {renderTable()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default TransactionList;
