import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom"
import logo from '../images/sasai-remit-logo.png';
import '../styles/global.css';
import { history } from "../history/index";
import config from '../env-config.json';
import ServerData from '../utils/ServerData';
import { AuthContext } from '../state';

function Header(props) {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         profile: {
    //             firstName: '',
    //             lastName: '',
    //             middleName: '',
    //             agent: 0,
    //             branch: 0
    //         },
    //         branchData: {
    //             name: '',
    //             city: '',
    //             branch_name: ''
    //         }
    //     }
    // }

    const [profileFirstName, setProfileFirstName] = useState('');
    const [profileLasttName, setProfileLasttName] = useState('');
    const [profileMiddleName, setProfileMiddleName] = useState('');
    const [profileEmail, setProfileEmail] = useState('');
    const [profileAgent, setProfileAgent] = useState(0);
    const [profileBranch, setProfileBranch] = useState(null);
    const [branchDataName, setBranchDataName] = useState('');
    const [branchDataCity, setBranchDataCity] = useState('');
    const [branchDataBranchName, setBranchDataBranchName] = useState('');
    const [userIsAManager, setUserIsAManager] = useState(false);

    const { state, dispatch } = useContext(AuthContext);

    // useEffect(() => setUserIsAManager(localStorage.getItem("isManager") === "isManager"));
    useEffect(() => {setUserIsAManager(localStorage.getItem("isManager") === "isManager")}, []);

    useEffect(() => {
        // let user = JSON.parse(localStorage.getItem('user'));
        // fetch(config.api_url + 'profile/', ServerData.getAuthHeaders())
        fetch(config.api_url + 'profile/', 
        {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',
                    'Authorization': 'Bearer ' + state.accessToken,
                    'App-Id': config.app_id,}
        })
            .then(
                function (response) {
                    if (response.status === 403) {
                        // localStorage.removeItem("user");
                        history.push({
                            pathname: "/login",
                            state: {
                            }
                        });
                    }
                }
            ).catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }, []);

    useEffect(() => {
        // fetch(config.api_url + 'profile/', ServerData.getAuthHeaders())
        fetch(config.api_url + 'profile/', 
            {
                mode: 'cors',
                credentials: 'include',
                method: 'GET',
                headers: {'Content-Type': 'application/vnd.api+json',
                        'Accept': 'application/vnd.api+json',
                        'Authorization': 'Bearer ' + state.accessToken,
                        'App-Id': config.app_id,}
            })
            .then(res => res.json())
            .then(
                (result) => {

                    // this.setState({
                    //     profile: {
                    //         firstName: result.data.attributes.first_name,
                    //         middleName: result.data.attributes.middle_name,
                    //         lastName: result.data.attributes.last_name,
                    //         agent: result.data.relationships.cash_pickup_provider,
                    //         branch: result.data.relationships.cash_pickup_location,
                    //         email: result.data.attributes.email
                    //     }
                    // }, () => {
                    //     // console.log("isManager....", !!result.data.relationships.cash_pickup_location)
                    //     localStorage.setItem("isManager", !!result.data.relationships.cash_pickup_location ? "isNotManager" : "isManager")
                    //     console.log("other problem", localStorage.getItem("isManager"))
                    //     window.profile = this.state.profile;
                    //     this.setState({ "userIsAManager": localStorage.getItem("isManager") === "isManager" });
                    // });

                    setProfileFirstName(result.data.attributes.first_name);
                    setProfileMiddleName(result.data.attributes.middle_name);
                    setProfileLasttName(result.data.attributes.last_name);
                    setProfileAgent(result.data.relationships.cash_pickup_provider);
                    setProfileBranch(result.data.relationships.cash_pickup_location);
                    setProfileEmail(result.data.attributes.email);

                    // localStorage.setItem("isManager", !!result.data.relationships.cash_pickup_location ? "isNotManager" : "isManager")
                    if(!!result.data.relationships.cash_pickup_location) {
                        console.log("NOT A MANAGER")
                    } else {
                        dispatch({ type: 'IS_MANAGER'})
                    }
                    // setUserIsAManager(localStorage.getItem("isManager") === "isManager");
                    // console.log("other problem", localStorage.getItem("isManager"))

                },
            ).catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }, []);

    useEffect(() => {
        // window.profile = this.state.profile;
        window.profile = {
            profile: {
                firstName: profileFirstName,
                middleName: profileMiddleName,
                lastName: profileLasttName,
                agent: profileAgent,
                branch: profileBranch,
                email: profileEmail,
            }
        }

        // this.setState({ "userIsAManager": localStorage.getItem("isManager") === "isManager" });
        // setUserIsAManager(localStorage.getItem("isManager") === "isManager");

        //console.log("Branch >>>>>>>>>>>>> " + this.state.profile.branch)
    }, [profileFirstName, profileMiddleName, profileLasttName, profileAgent, profileBranch, profileEmail])

    useEffect(() => {
        // if (this.state.profile.branch != null) {
        if (profileBranch != null) {
            // let branch_id = this.state.profile.branch.data.id
            let branch_id = profileBranch.data.id
            fetch(config.api_url + 'cash_pickup_locations/' + branch_id + '?include=country,provider')
                .then(ress => ress.json())
                .then(
                    (results) => {
                        console.log(JSON.stringify(results))

                        // this.setState({
                        //     branchData: {
                        //         branch_name: results.data.attributes.name,
                        //         city: results.data.attributes.city,
                        //         name: results.included[0].attributes.name
                        //     }
                        // })

                        setBranchDataBranchName(results.data.attributes.name);
                        setBranchDataCity(results.data.attributes.city);
                        setBranchDataName(results.included[0].attributes.name);

                        //console.log(JSON.stringify("Something>>>>>>>>> " + this.state.branchData))
                        //localStorage.setItem("branch" , this.state.branchData)
                    }
                )
        }
    }, [profileBranch]);

    useEffect(() => {
        console.log('branchDataName', branchDataName)
        console.log('branchDataCity', branchDataCity)
        console.log('branchDataBranchName', branchDataBranchName)
    }, [branchDataName, branchDataName, branchDataCity])

    const renderHomeLink = () => {
        let url = window.location.href;
        url = url.slice(url.lastIndexOf('/') + 1);

        if (url === "home") {
            return (
                <li className="current-menu-item">
                    <Link to="/home"> Home </Link>
                </li>
            );
        } else {
            return (
                <li >
                    <Link to="/home"> Home </Link>
                </li>
            )
        }

    }

    const renderSearchLink = () => {
        let url = window.location.href;
        url = url.slice(url.lastIndexOf('/') + 1);

        if (url === "search") {
            return (
                <li className="current-menu-item">
                    <Link to="/search"> Search Transaction </Link>
                </li>
            );
        } else {
            return (
                <li >
                    <Link to="/search"> Search Transaction </Link>
                </li>
            )
        }

    }

    const renderTransactionsLink = () => {
        let url = window.location.href;
        url = url.slice(url.lastIndexOf('/') + 1);

        if (url === "transactions") {
            return (
                <li className="current-menu-item">
                    <Link to="/transactions"> Transaction List </Link>
                </li>
            );
        } else {
            return (
                <li >
                    <Link to="/transactions"> Transaction List </Link>
                </li>
            )
        }
    }

    const renderCashoutReportLink = () => {
        let url = window.location.href;
        url = url.slice(url.lastIndexOf('/') + 1);

        if (url === "cashout-report") {
            return (
                <li className="current-menu-item">
                    <Link to="/cashout-report"> Cashout Report  </Link>
                </li>
            );
        } else {
            return (
                <li >
                    <Link to="/cashout-report"> Cashout Report </Link>
                </li>
            )
        }
    }

    const onLogout = (message) => {
        // let user = localStorage.getItem("user");
        // localStorage.removeItem("user");
        // localStorage.removeItem("isManager");

        fetch(config.auth_url + 'web/logout/', {
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + state.accessToken,
                'App-Id': config.app_id,
            }
        }).then((response) => {
                if (response.status !== 200) {
                    console.log('Status Code', response.status);
                    return;
                }

                response.json().then((data) => {

                    // Delete access token and isAuthenticated to false
                    dispatch({ type: "LOGOUT", payload: "" });
                    history.push({
                        pathname: "/login",
                        state: {
                            //message : message
                        }
                    });

                });
            }
        ).catch((err) => {
            console.log('Fetch Error', err);
        });

        
        // this.setState({ "userIsAManager": localStorage.getItem("isManager") === "isManager" })
        // setUserIsAManager(localStorage.getItem("isManager") === "isManager");
    }

    // let userIsAManager = localStorage.getItem("isManager") === "isManager"
    return (
        <header className="site-header">
            <div className="top-header-bar">
                <div className="container">
                    <div className="row flex-wrap justify-content-center justify-content-lg-between align-items-lg-center">
                        <div className="col-12 col-lg-8 d-none d-md-flex flex-wrap justify-content-center justify-content-lg-start mb-3 mb-lg-0">
                            <div className="header-bar-email">
                                Welcome,  <Link to="/profile"> {profileFirstName} {profileLasttName}</Link>
                                {/* <span> | {this.state.branchData.name}  - {this.state.branchData.branch_name}</span> */}
                                <span> | {branchDataName}  - {branchDataBranchName}</span>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4 d-flex flex-wrap justify-content-center justify-content-lg-end align-items-center">
                            <div className="header-bar-power">
                                <Link to="" onClick={() => onLogout("")} >
                                    <i className="fa fa-power-off"></i>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="nav-bar bg-white custom-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
                            <div className="site-branding d-flex align-items-center">
                                <Link to="/search" className="d-block">
                                    <img height="auto" width={158} className="d-block" src={logo} alt="logo" />
                                </Link>
                            </div>

                            <nav className="site-navigation d-flex justify-content-end align-items-center">
                                <ul className="d-flex flex-column flex-lg-row justify-content-lg-end align-content-center">
                                    {/* {userIsAManager && this.renderHomeLink()} */}
                                    {renderSearchLink()}
                                    {/* {userIsAManager && renderTransactionsLink()}
                                    {userIsAManager && renderCashoutReportLink()} */}
                                    {state.isManager && renderTransactionsLink()}
                                    {state.isManager && renderCashoutReportLink()}
                                </ul>
                            </nav>

                            <div className="hamburger-menu d-lg-none">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );

}

export default Header;

