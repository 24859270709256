import React, { useState, useEffect, useContext } from "react";
import DataParser from "../utils/DataParser";
import ServerData from "../utils/ServerData";
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from '../env-config.json';
import { history } from "../history/index";

import '../styles/global.css';
import '../styles/transaction.css';
import { AuthContext } from "../state";

function TransactionDetails(props) {
    // state = {
    //         transaction: null,
    //         recipient: {
    //             name: ''
    //         },
    //         sender: {
    //             firstName: '',
    //             lastName: ''
    //         },
    //         included: null,
    //         transID: "",
    //         cAmount: 0,
    //         comment: "",
    //         cashoutProccessed: false,
    //         cashoutResponse: null,
    //         loading: true,
    //         loaderColor: "#0b5ca9",
    //         loaderType: "bars",
    //         errorMessage: "", 
    //         hasError: false,
    //     }

    const [transaction, setTransaction] = useState(null);
    const [recipientName, setRecipentName] = useState('');
    const [senderFirstName, setSenderFirstName] = useState('');
    const [senderLastName, setSenderLastName] = useState('');
    const [included, setIncluded] = useState(null);
    const [transID, setTransID] = useState('');
    const [cAmount, setCAmount] = useState(0);
    const [comment, setComment] = useState('');
    const [cashoutProccessed, setCashoutProccessed] = useState(false);
    const [cashoutResponse, setCashoutResponse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loaderColor, setLoaderColor] = useState('#0b5ca9');
    const [loaderType, setLoaderType] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [hasError, setHasError] = useState('');
    const [country, setCountry] = useState('');
    const [currency, setCurrency] = useState('');
    const [options, setOptions] = useState('');
    const [transferState, setTransferState] = useState({});

    const { state } = useContext(AuthContext);
     
     
     useEffect(() => {
        setTransferState({
            transaction,
            recipient: {
                name: recipientName
            },
            sender: {
                firstName: senderFirstName,
                lastName: senderLastName
            },
            included,
            transID,
            cAmount,
            comment,
            cashoutProccessed,
            cashoutResponse,
            loading,
            loaderColor,
            loaderType,
            errorMessage,
            hasError,
            country, 
            currency,  
            options,
        })
    }, [transaction, recipientName,  senderFirstName,  
        senderLastName,  included, transID,  cAmount, comment,  
        cashoutProccessed, cashoutResponse, loading, loaderColor, 
        loaderType,  errorMessage, hasError, country, currency,  
        options ])

    const renderErrorMessage = () => {
        // const { errorMessage, hasError } = this.state;
        return (
            <div className="">
                <div
                    className="alert alert-warning alert-dismissible fade show"
                    role="alert"
                >
                    <strong>Error: </strong> {errorMessage}
                    <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        );

    }

    // const handleChange = (event) => {
    //     this.setState({ [event.target.id]: event.target.value });
    // }

    const handleCashout = (event) => {
        event.preventDefault();
        // const { transID, comment, cAmount } = this.state;
        /*
        if(comment !== null){
            console.log("Has comment >>>>>>>>>>>>>> ")
            let data = {
                data: {
                    type:'transactions',
                    id: transID,
                    attributes: {
                        status: 'C',
                        payout_amount: cAmount,
                        payout_comment: comment
                    },
                    relationships: { 
                    }
                }
            };
    
            console.log(JSON.stringify(data))
            let user = JSON.parse(localStorage.getItem('user'));
            fetch( config.api_url + 'transactions/' + this.props.match.params.id +  '/', {
                mode: 'cors',
                credentials: 'include',
                method: 'PUT',
                headers: {'Content-Type': 'application/vnd.api+json',
                          'Accept': 'application/vnd.api+json',
                          'Authorization': 'Bearer ' + user.access},
                body: JSON.stringify(data)
    
            }).then(res => res.json())
            .then(
              (result) => {
                  console.log(JSON.stringify(result));
                  this.setState({
                      cashoutResponse: result.data.attributes,
                      cashoutProccessed: true
                  })
                  //localStorage.setItem('transaction', this.state.transaction);
              },
            ).catch((err) => {
                this.setState({
                    loading: false                
                })
                console.log('Fetch Error :-S', err);
            });   

        }else {*/
        let data = {
            data: {
                type: 'transactions',
                id: transID,
                attributes: {
                    status: 'C',
                },
                relationships: {
                }
            }
        };
        // let user = JSON.parse(localStorage.getItem('user'));
        // fetch( config.api_url + 'transactions/' + this.props.match.params.id +  '/', {
        fetch(config.api_url + 'transactions/' + props.match.params.id + '/', {
            mode: 'cors',
            credentials: 'include',
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/vnd.api+json',
                'Accept': 'application/vnd.api+json',
                'Authorization': 'Bearer ' + state.accessToken,
                'App-Id': config.app_id,
            },
            body: JSON.stringify(data)

        }).then(res => res.json())
            .then(
                (result) => {
                    // console.log(JSON.stringify(result));
                    //   this.setState({
                    //       cashoutResponse: result.data.attributes,
                    //       cashoutProccessed: true
                    //   })
                    setCashoutResponse(result.data.attributes);
                    setCashoutProccessed(true);
                    //localStorage.setItem('transaction', this.state.transaction);
                },
            ).catch((err) => {
                // this.setState({
                //     loading: false,
                //     errorMessage: err, 
                //     hasError: true            
                // })
                setLoading(false);
                setErrorMessage(err);
                setHasError(true);
                console.log('Fetch Error :-S', err);
            });

    }

    const processCashoutWithComment = () => {
        //event.preventDefault();
        // const { transID, comment, cAmount } = this.state;
        let data = {
            data: {
                type: 'transactions',
                id: transID,
                attributes: {
                    status: 'C',
                    payout_amount: cAmount,
                    payout_comment: comment
                },
                relationships: {
                }
            }
        };

        //console.log(JSON.stringify(data))
        // let user = JSON.parse(localStorage.getItem('user'));
        // fetch( config.api_url + 'transactions/' + this.props.match.params.id +  '/', {
        fetch(config.api_url + 'transactions/' + props.match.params.id + '/', {
            mode: 'cors',
            credentials: 'include',
            method: 'PUT',
            headers: {
                'Content-Type': 'application/vnd.api+json',
                'Accept': 'application/vnd.api+json',
                'Authorization': 'Bearer ' + state.accessToken,
                'App-Id': config.app_id,
            },
            body: JSON.stringify(data)

        }).then(res => res.json())
            .then(
                (result) => {
                    //console.log(JSON.stringify(result));
                    //   this.setState({
                    //       cashoutResponse: result.data.attributes,
                    //       cashoutProccessed: true
                    //   })
                    setCashoutResponse(result.data.attributes);
                    setCashoutProccessed(true);
                    //localStorage.setItem('transaction', this.state.transaction);
                },
            ).catch((err) => {
                // this.setState({
                //     loading: false,
                //     errorMessage: err,
                //     hasError: true
                // })
                setLoading(false);
                setErrorMessage(err);
                setHasError(true);
                console.log('Fetch Error :-S', err);
            });



    }

    useEffect(() => {
        // let isManager = localStorage.getItem("isManager");
        // console.log("isManager", isManager)
        console.log("isManager", state.isManager)
        // let uri = ((isManager === "isManager") ? (
        let uri = ((state.isManager) ? (
            // config.api_url + 'transactions/' + this.props.match.params.id +
            config.api_url + 'transactions/' + props.match.params.id +
            '/?include=currency,sender,sender.country,beneficiary,beneficiary.country'
        ) : (
                // config.api_url + 'transactions/' + this.props.match.params.id +
                config.api_url + 'transactions/' + props.match.params.id +
                '/?include=currency,sender,sender.country,beneficiary,beneficiary.country,available_payout_currencies'
            ));

        fetch(uri,
            // ServerData.getAuthHeaders()
            {
                mode: 'cors',
                credentials: 'include',
                method: 'GET',
                headers: {'Content-Type': 'application/vnd.api+json',
                        'Accept': 'application/vnd.api+json',
                        'Authorization': 'Bearer ' + state.accessToken,
                        'App-Id': config.app_id,}
            }
        ).then(res => res.json())
            .then(
                (result) => {
                    console.log("RESULT", result)
                    if (!!result.errors) {
                        if (result.errors[0].status === "404") {
                            return (
                                // this.setState({
                                // hasError: true,
                                // errorMessage: "Transaction not found"
                                // }),
                                history.push({
                                    pathname: "/search",
                                    // state: {
                                    //     hasError: true,
                                    //     errorMessage: "Transaction not found"
                                    // }
                                })
                            )
                        }
                    }
                    // get country
                    let country = result.included.filter(x => x.type === "countries")
                        .find(x => x.id === result.included.find(x => x.type === "beneficiaries").relationships.country.data.id).attributes;
                    // get currency
                    let currency = result.included.filter(x => x.type === "currencies")
                        .find(x => x.id === result.data.relationships.currency.data.id).attributes;
                    // get options and create an array of [{"letter_code": string, "id": number}]
                    // let options = ((isManager === "isManager") ? (
                    let options = ((state.isManager) ? (
                        [currency]
                    ) : (
                            result.included.filter(x => x.type === "currencies")
                                .filter(x => result.data.relationships.available_payout_currencies.data.filter(y => x.id === y.id))
                                .map(x => x = { "letter_code": x.attributes.letter_code, "id": x.id }))
                    );
                    console.log(JSON.stringify(result))
                    // this.setState({
                    //     transaction: DataParser.getTransaction(result),
                    //     sender: DataParser.getSender(result),
                    //     recipient: DataParser.getBeneficiary(result),
                    //     cAmount: result.data.attributes.amount,
                    //     transID: result.data.id,
                    //     country: country,
                    //     currency: currency,
                    //     options: options,
                    //     loading: false,
                    // });
                    setTransaction(DataParser.getTransaction(result));
                    setSenderFirstName(DataParser.getSender(result).firstName);
                    setSenderLastName(DataParser.getSender(result).lastName);
                    setRecipentName(DataParser.getBeneficiary(result).name);
                    setCAmount(result.data.attributes.amount);
                    setTransID(result.data.id);
                    setCountry(country);
                    setCurrency(currency);
                    setOptions(options);
                    setLoading(false);
                },
            ).catch(err => {
                console.log('Fetch Error :-S', err);
                console.log("error", err)
                // this.setState({
                //     errorMessage: err.errors[0].details, 
                //     hasError: true
                // })
            });
    }, []);






    const renderSender = () => {
        return (
            <div className="col-6 ">
                <div className="card" style={{ marginTop: "40px" }}>
                    <div className="card-body">
                        <h5 className="card-title">Sender Details</h5>
                        <hr></hr>
                        <div className="row">
                            <div className="col-6">
                                <p className="card-text"><b>Full Name: </b></p>

                            </div>
                            <div className="col-6">
                                <p className="card-text">
                                    {/* {this.state.sender.firstName} {this.state.sender.lastName} */}
                                    {senderFirstName} {senderLastName}
                                </p>

                            </div>
                            <div className="col-12" style={{ marginTop: "15px" }}>
                            </div>
                            <div className="col-6">
                                <p className="card-text"><b>Location: </b></p>

                            </div>
                            <div className="col-6">
                                <p className="card-text">South Africa, ZA</p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    const renderBeneficiary = () => {
        return (
            <div className="col-6 ">
                <div className="card" style={{ marginTop: "40px" }}>
                    <div className="card-body">
                        <h5 className="card-title">Beneficiary Details</h5>
                        <hr></hr>

                        <div className="row">
                            <div className="col-6">
                                <p className="card-text"><b>Full Name: </b></p>

                            </div>
                            <div className="col-6">
                                <p className="card-text">
                                    {/* {this.state.recipient.name} */}
                                    {recipientName}
                                </p>

                            </div>
                            <div className="col-12" style={{ marginTop: "15px" }}>
                            </div>
                            <div className="col-6">
                                <p className="card-text"><b>Location: </b></p>

                            </div>
                            <div className="col-6">
                                {/* <p className="card-text">{this.state.country.name}, {this.state.country.alpha_2_code}</p> */}
                                <p className="card-text">{country.name}, {country.alpha_2_code}</p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    const renderTransaction = () => {
        // if (this.state.cashoutProccessed) {
        if (cashoutProccessed) {
            console.log("Cashout has been procces>>>>>>>>> ");
            return (
                <div className="col-12">
                    <div className="card" style={{ marginTop: "20px" }}>
                        <div className="card-header">Transaction Details</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-3">
                                    <p className="card-text"><b>Transaction Code: </b></p>
                                </div>
                                <div className="col-3">
                                    {/* {this.state.transaction.code} */}
                                    {transaction.code}
                                </div>
                                <div className="col-3">
                                    <p className="card-text"><b>Status: </b></p>
                                </div>
                                <div className="col-3">
                                    {/* {this.renderStatus(this.state.cashoutResponse)} */}
                                    {renderStatus(cashoutResponse)}
                                </div>
                                <div className="col-12"></div>
                                <div className="col-3">
                                    <p className="card-text"><b>Amount: </b></p>
                                </div>
                                <div className="col-3">
                                    {/* {this.state.currency.letter_code} {this.state.transaction.amount} */}
                                    {currency.letter_code} {transaction.amount}
                                </div>
                                <div className="col-3">
                                    <p className="card-text"><b>Date: </b></p>
                                </div>
                                <div className="col-3">
                                    {/* {this.state.transaction.log_date} */}
                                    {transaction.log_date}
                                </div>
                                <div className="col-12"></div>
                            </div>

                        </div>
                    </div>
                </div>
            );

        } else {
            return (
                <div className="col-12">
                    <div className="card" style={{ marginTop: "20px" }}>
                        <div className="card-header">Transaction Details</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-3">
                                    <p className="card-text"><b>Transaction Code: </b></p>
                                </div>
                                <div className="col-3">
                                    {/* {this.state.transaction.code} */}
                                    {transaction.code}
                                </div>
                                <div className="col-2">
                                    <p className="card-text"><b>Status: </b></p>
                                </div>
                                <div className="col-4">
                                    {/* {this.renderStatus(this.state.transaction)} */}
                                    {renderStatus(transaction)}
                                </div>
                                <div className="col-12" style={{ marginTop: "15px" }}></div>
                                <div className="col-3">
                                    <p className="card-text"><b>Amount: </b></p>
                                </div>
                                <div className="col-3">
                                    {/* {this.state.currency.letter_code} {this.state.transaction.amount} */}
                                    {currency.letter_code} {transaction.amount}
                                </div>
                                <div className="col-2">
                                    <p className="card-text"><b>Date: </b></p>
                                </div>
                                <div className="col-4">
                                    {/* {this.state.transaction.log_date} */}
                                    {transaction.log_date}
                                </div>
                                <div className="col-12"></div>
                            </div>

                        </div>
                    </div>
                </div>
            );
        }

    }

    const renderStatus = (tran) => {
        if (tran.status === 'A') {
            return 'AUTHORISED at ' + tran.status_date
        } else if (tran.status === 'C') {
            return 'COMPLETED at ' + tran.status_date
        } else if (tran.status === 'P') {
            return 'PENDING at ' + tran.status_date
        } else {
            return 'AUTHORISED at ' + tran.status_date
        }

    }

    const renderFetching = () => {
        // const { loaderType, loaderColor } = this.state;
        // if (this.state.loading) { 
        if (loading) {
            return (
                <div className="col-6 offset-sm-3 text-center">
                    <p> Loading transaction. Please wait...</p>
                    <ReactLoading className="searching--spinner--100" type={loaderType} color={loaderColor} />
                </div>
            );
        }
    }


    const renderCashout = () => {
        // const { cAmount, comment } = this.state;
        // if (this.state.cashoutProccessed) {
        if (cashoutProccessed) {
            return (
                <div className="col-12">
                    <div className="card text-center" style={{ marginTop: "20px" }}>
                        <div className="card-header">
                            Payout Details
                        </div>
                        <div className="card-body text-left">
                            <div class="alert alert-primary" role="alert">
                                <h4 class="alert-heading">Cashout processed successfully!</h4>
                                <p> Please print receipt by clicking the button below.  </p>
                                <hr />
                                <p class="mb-0">
                                    {/* <Link to={{ pathname: '/print', state: this.state }} className="btn btn-secondary" > Print Receipt  </Link> */}
                                    <Link to={{ pathname: '/print', state: transferState }} className="btn btn-secondary" > Print Receipt  </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            // let isCompleted = this.state.transaction.status === 'C';
            let isCompleted = transaction.status === 'C';
            // let userIsAManager = localStorage.getItem("isManager") === "isManager";
            let userIsAManager = state.isManager;
            let disableSubmission = isCompleted || userIsAManager;
            return (
                <div className="col-12">
                    <div className="card text-center" style={{ marginTop: "20px", paddingBottom: "30px" }}>
                        <div className="card-header">
                            Payout Details
                    </div>
                        <div className="card-body text-left">

                            <div className="col-sm-6 offset-sm-3">
                                {/* <form onSubmit={this.handleCashout}> */}
                                <form onSubmit={handleCashout}>
                                    <div className="form-group">
                                        <label style={{ marginBottom: "15px" }}>Amount paid(*):</label>
                                        <div className="row">
                                            <div className="col-3">
                                                <select name="su_currency__100" id="su_currency__100" className="custom-select mr-sm-2"
                                                    // value={this.state.currency.letter_code} onChange={this.handleChange}>
                                                    value={currency.letter_code} onChange={e => setCurrency(e.target.value)}>
                                                    {/* {this.state.options.map(x => <option key={x.id}>{x.letter_code}</option>)} */}
                                                    {options.map(x => <option key={x.id}>{x.letter_code}</option>)}
                                                    {/* <option value = "USD">USD</option>    */}
                                                </select>
                                            </div>
                                            <div className="col-9">
                                                <input type="number" name="cAmount" id="cAmount" className="form-control"
                                                    // value={cAmount} onChange={this.handleChange} disabled={true}
                                                    value={cAmount} onChange={e => setCAmount(e.target.value)} disabled={true}
                                                    // placeholder={this.state.currency.letter_code + " 0.08"} />
                                                    placeholder={currency.letter_code + " 0.08"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Comment :</label>
                                        <input name="comment" id="comment" type="text" className="form-control ftt-form"
                                            // placeholder="Payout comment" value={comment} onChange={this.handleChange} />
                                            placeholder="Payout comment" value={comment} onChange={e => setComment(e.target.value)} />
                                    </div>


                                    <button disabled={disableSubmission} style={{ cursor: disableSubmission && "not-allowed" }} type="submit" className="btn btn-success btn-block">
                                        Process Cashout
                            </button>
                                </form>
                            </div>

                        </div>

                    </div>
                </div>
            )
        }

    }


    // RETURN ============

    // if (this.state.hasError) {
    if (hasError) {
        // return this.renderErrorMessage();
        return renderErrorMessage();
    // } else if (this.state.loading) {
    } else if (loading) {
        return (
            <div className="our-causes pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="cause-wrap">
                                <div className="cause-content-wrap">
                                    <header className="entry-header d-flex flex-wrap align-items-center">
                                        <h3 className="entry-title w-100 m-0">
                                        </h3>
                                    </header>
                                    <div className="entry-content">
                                        {/* {this.renderFetching()} */}
                                        {renderFetching()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="our-causes pt-0">
                <div className="container">
                    <div className="row">
                        {/* {this.renderSender()} */}
                        {renderSender()}
                        {/* {this.renderBeneficiary()} */}
                        {renderBeneficiary()}
                        {/* {this.renderTransaction()} */}
                        {renderTransaction()}
                        {/* {this.renderCashout()} */}
                        {renderCashout()}
                    </div>
                </div>
            </div>
        );

    }

}
export default TransactionDetails;
