import React, { Component } from "react";

import logo from '../images/sasai-remit-logo.png';
import '../styles/global.css';
import '../styles/transaction.css';
import config from '../env-config.json';
import ServerData from '../utils/ServerData';
import { AuthContext } from '../state';


class Receipt extends Component {
    static contextType = AuthContext;

    constructor(props) {
      super(props);
      this.merchant = React.createRef();

      this.state = {
        profile: {
            firstName: '',
            lastName: '',
            middleName: '',
            agent:0,
            branch: 0
        },
        branchData: {
            name: '',
            city: '',
            branch_name: ''
        }
    }

    }
    
    componentWillMount(){
        const { state } = this.context;
        // fetch(config.api_url + 'profile/', ServerData.getAuthHeaders())
        fetch(config.api_url + 'profile/', 
        {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',
                    'Authorization': 'Bearer ' + state.accessToken,
                    'App-Id': config.app_id,}
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log(JSON.stringify(result))
                this.setState({
                    profile: {
                        firstName: result.data.attributes.first_name,
                        middleName: result.data.attributes.middle_name,
                        lastName: result.data.attributes.last_name,
                        agent: result.data.relationships.cash_pickup_provider,
                        branch: result.data.relationships.cash_pickup_location,
                        mobile: `${result.data.attributes.mobile[0]}${result.data.attributes.mobile[1]}`
                    }
                });

                console.log(this.state.profile);

                if(this.state.profile.branch != null){
                    let branch_id = this.state.profile.branch.data.id
                    fetch(config.api_url + 'cash_pickup_locations/'+ branch_id + '?include=country,provider')
                    .then(ress => ress.json())
                    .then(
                        (results) => {
                            this.setState({
                                branchData: {
                                    branch_name: results.data.attributes.name,
                                    city: results.data.attributes.city,
                                    name: results.included[0].attributes.name
                                }
                            })

                            console.log(JSON.stringify(this.state.branchData))
                        }
                    )
                }
            },
        ).catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
   }

    render() {
        console.log({props: this.props})
        return (
            <div>
                {this.renderCustomerReceipt()}
                {this.renderMerchantCopy()}
            </div>  
        )
    }


    renderRecipient() {
        return (
        <div>
            <div className="receipt-body-item">
                <p className="title-item">Recipient </p>
            </div>
            <div className="hr-lg"></div>
            <div className="row-items-receipt">
                <div className="col2">
                    <p className = "text-bd">FULL NAME </p>
                    <p className="prix">{this.props.location.state.recipient.name} </p>
                </div>
                <div className="col2">
                    <p className = "text-bd">MOBILE NUMBER </p>
                    {/* <p className="prix">263774222760 </p> */}
                    <p className="prix">{this.state.profile.mobile} </p>
                </div>
                <div className="col2">
                    <p className = "text-bd">Country  </p>
                    {/* <p className="prix">Zimbabwe, ZW </p> */}
                    <p className="prix">{this.props.location.state.country.name}, {this.props.location.state.country.alpha_2_code} </p>
                </div>
            </div>
            </div>
        );
    }

    renderSender() {
        return(
            <div>
            <div className=".receipt-body-item">
            <p className="title-item">Sender </p>
            </div>
            <div className="hr-lg"></div>
            <div className="produits">
                <div className="col2">
                    <p className = "text-bd">FULL NAME </p>
                    <p className="prix"> { this.props.location.state.sender.firstName} { this.props.location.state.sender.lastName} </p>
                </div>
                <div className="col2">
                    <p className = "text-bd">LOCATION  </p>
                    <p className="prix">South Africa, ZA </p>
                </div>
            
            </div>
            </div>
        );

    }

    renderTransaction() {
        return (
        <div>
        <div className=".receipt-body-item">
            <p className="title-item">Transaction Details</p>
        </div>
        <div className="hr-lg"></div>
        <div className="produits">
            <div className="col2">
                <p className = "text-bd">AMOUNT</p>
                <p className="prix"> {this.props.location.state.transaction.amount }</p>
            </div>
            <div className="col2">
                <p className = "text-bd">TRANSACTION ID</p>
                <p className="prix"> {this.props.location.state.transaction.code }</p>
            </div>
            <div className="col2">
                <p className = "text-bd">STATUS </p>
                <p className="prix"> { this.renderStatus(this.props.location.state.cashoutResponse.status)}</p>
            </div>
            <div className="col2">
                <p className = "text-bd">DATE</p>
                <p className="prix"> {this.props.location.state.cashoutResponse.status_date} </p>
            </div>
        </div>
        </div>
        );
    }

    renderStatus(status){
        if(status === 'A') {
            return 'AUTHORISED'
         }else  if(status === 'C') {
            return 'COMPLETE'
         }else  if(status === 'P') {
            return 'PENDING'
         }else {
            return 'COMPLETE'
         }
        
    }

    renderCurrentTime() {
       return   new Date().toLocaleString();


    }


    renderCustomerReceipt() {
        if(this.transaction !== null){
            return (
            <div className="receipt-container">
                <div className="receipt">
                    <div className="head-receipt">
                        <img height="auto" width={158} src={ logo } alt="logo" />
                        <hr />
                        <p><b>Cashier:</b> {this.state.profile.firstName} {this.state.profile.lastName}</p>
                        <p><b>Location:</b> {this.state.branchData.name}, {this.state.branchData.branch_name}</p>
                        <p><b>Time: </b>  { this.renderCurrentTime()} </p>
                    </div>
                    <div className="receipt-body">
                        {this.renderRecipient()}
                        {this.renderSender()}
                        {this.renderTransaction()}
                    
                        <div className=".receipt-body-item">
                            <br />
                            <p>I declare that I agree with the terms and conditions. (https://sasairemit.com/terms-of-use/)</p>
                            <br />
                        </div>
                        <div className = "row">
                            <div className = "col-6">
                            <div className="hr-sign"></div>
                            <p> <b>Customer Signature</b> </p>
                            </div>
                            <div className = "col-6">
                            <div className="hr-sign"></div>
                            <p> <b>Authorized By:</b> </p>
                            </div>
                            <div className = "col-12">
                                <b> Receipt not valid without proper authorized signature </b>
                            </div>

                        </div>
                        <hr />
                        <p className = "text-center"><b>(Customer Copy)</b></p>                        
                        </div>
                </div>
            </div>
            );
        }

    }

    renderMerchantCopy() {
        if(this.transaction !== null){
            return (
            <div id = "merchant" ref={this.merchant}>
            <div className="receipt-container">
                <div className="receipt">
                    <div className="head-receipt">
                        <img height="auto" width={158} src={ logo } alt="logo" />
                        <hr />
                        <p><b>Cashier:</b> {this.state.profile.firstName} {this.state.profile.lastName}</p>
                        <p><b>Location:</b> {this.state.branchData.name}, {this.state.branchData.branch_name}</p>
                        <p><b>Time: </b>  { this.renderCurrentTime()} </p>
                    </div>
                    <div className="receipt-body">
                        {this.renderRecipient()}
                        {this.renderSender()}
                        {this.renderTransaction()}
                    
                        <div className=".receipt-body-item">
                            <br />
                            <p>I declare that I agree with the terms and conditions. (https://sasairemit.com/terms-of-use/)</p>
                            <br />
                        </div>
                        <div className = "row">
                            <div className = "col-6">
                            <div className="hr-sign"></div>
                            <p> <b>Customer Signature</b> </p>
                            </div>
                            <div className = "col-6">
                            <div className="hr-sign"></div>
                            <p> <b>Authorized By:</b> </p>
                            </div>
                            <div className = "col-12">
                                <b> Receipt not valid without proper authorized signature </b>
                            </div>

                        </div>
                        <hr />
                        <p className = "text-center"><b>(Merchant Copy)</b></p>                        
                        </div>
                </div>
            </div>
            </div>
            );
        }

    }

}

export default Receipt;