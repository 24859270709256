import React, { useState, useEffect, useRef, useContext } from "react";
import ReactToPrint from "react-to-print";
import { Table, Icon, Pagination } from "antd";
import Select from "react-select";
import ServerData from "../utils/ServerData";
import DataParser from "../utils/DataParser";
import exportObjectsToCSV from "../utils/exportObjectsToCSV";
import { history } from "../history/index";
import dateFormat from "dateformat";
import ReactLoading from "react-loading";
import DatePicker from "react-datepicker";
import config from "../env-config.json";
import { AuthContext } from '../state';

import "../styles/global.css";
import "../styles/transaction.css";
import "react-datepicker/dist/react-datepicker.css";

const toDateString = (date) => {
  return dateFormat(date, "yyyy-mm-dd");
}

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "Sender Name",
    dataIndex: "sender",
    key: "sender"
  },
  {
    title: "Beneficiary Name",
    dataIndex: "beneficiary",
    key: "beneficiary"
  },
  {
    title: "Branch",
    dataIndex: "cash_collection_branch",
    key: "cash_collection_branch"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status"
  },
  {
    title: "Date",
    dataIndex: "status_date",
    key: "status_date"
  },
  {
    title: "Processed By",
    dataIndex: "agent",
    key: "agent"
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount"
  }
];

const csvColumns = [
  { accessor: "sender", header: "sender", cast: "string" },
  { accessor: "beneficiary", header: "beneficiary", cast: "string" },
  { accessor: "code", header: "code", cast: "string" },
  { accessor: "amount", header: "amount", cast: "string" },
  { accessor: "id", header: "id", cast: "string" },
  { accessor: "status", header: "status", cast: "string" },
  { accessor: "status_date", header: "status_date", cast: "string" },
  { accessor: "cash_collection_branch", header: "cash_collection_branch", cast: "string" },
  { accessor: "agent", header: "agent", cast: "string" }
];

const options = [
  { value: "ALL", label: "All" },
  { value: "STW", label: "Steward" },
  { value: "ECO", label: "Econet" }
];

const ALL = options.filter(o => o.value === "ALL")[0];

const PAGE_SIZE = 100

function CashoutReport(props) {


  const [transactions, setTransactions] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [included, setIncluded] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loaderType, setLoaderType] = useState('bars');
  const [loaderColor, setLoaderColor] = useState('#0b5ca9');
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [filterBy, setFilterBy] = useState(ALL);
  const [submitted, setSubmitted] = useState(false);

  // TABLE PAGINATION STATE
  // totalNumberOfItems tableCurrentPage tablePages
  // matches count from API
  const [totalNumberOfItems, setTotalNumberOfItems] = useState('');
  // matches page from API
  const [tableCurrentPage, setTableCurrentPage] = useState('1');
  // matches pages from API
  const [tablePages, setTablePages] = useState('');

  const componentRef = useRef();
  const { state } = useContext(AuthContext);

  useEffect(() => {
    // check if the user is a manager or an agent
    // if agent redirect to search transaction page
    // if (localStorage.getItem("isManager") !== "isManager") {
    if (!state.isManager) {
      history.push({
        pathname: "/search",
        state: {
          //message : message
        }
      });
    }
  }, []);



  const handleExport = () => {
    exportObjectsToCSV(dataSource, csvColumns);
  };

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleSubmit = event => {
    event.preventDefault();
    requestTransactions();
  };

  useEffect(() => {
    requestTransactions();
  }, []);

  const requestTransactions = (pageNumber = '1') => {
    let filter = `?filter[status]=C&page[size]=${PAGE_SIZE}&page[number]=${pageNumber}`;

    setLoading(true);

    if (!!startDate) {
      console.log("startDate", filter)
      filter = `${filter}&filter[completed_date_from]=${toDateString(startDate)}&page[size]=${PAGE_SIZE}&page[number]=${pageNumber}`;
    }
    if (!!endDate) {
      console.log("endDate", filter)
      filter = `${filter}&filter[completed_date_to]=${toDateString(endDate)}&page[size]=${PAGE_SIZE}&page[number]=${pageNumber}`;
    }
    console.log({
      startDate: toDateString(startDate),
      endDate: toDateString(endDate)
    });

    console.log("final filter", filter)
    fetch(
      config.api_url +
      "transactions/" +
      filter +
      "&include=currency,payout_currency,sender,sender.country,beneficiary,beneficiary.country" +
      ",payout_agent,payout_branch,payout_branch.country,payout_branch.provider",
      // ServerData.getAuthHeaders()
      {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'Accept': 'application/vnd.api+json',
          'Authorization': 'Bearer ' + state.accessToken,
          'App-Id': config.app_id,
        }
      }
    )
      .then(response => {
        if (response.status === 400) {
          setLoading(false);
          setHasError(true);
          setErrorMessage("No transactions found! ");
          return;
        } else if(response.status > 400 && response.status < 600) {
          setLoading(false);
          setHasError(true);
          setErrorMessage("Error fetching transactions");
          return;
        } 

        response.json().then(result => {

          // destructuring from result.meta object
          const { pagination: { count, page, pages }} = result.meta

          // set pagination state
          setTotalNumberOfItems(count)
          setTableCurrentPage(page)
          setTablePages(pages)

          console.log({ result });
          console.log("result code", result.status)

          if (!!result.errors) {
            if (result.errors[0].status === "404") {
              setHasError(true);
              setErrorMessage("No transactions found! ");
              setLoading(false);
            }
          } else {
            let dataSource = DataParser.getCashoutReport(
              result.data,
              result.included
            );
            setLoading(false);
            setTransactions(result.data);
            setIncluded(result.included);
            setDataSource(dataSource);
          }
        });
      })
      .catch(err => {
        setHasError(true);
        setSubmitted(false);
        errorMessage("General error occured. Try again");
      });
  };



  const loadTransaction = transaction => {
    history.push({
      pathname: "/transaction/" + transaction.id,
      state: {
        transaction: transaction
      }
    });
  };

  const renderTable = () => {
    if (!loading) {
      return (
        <Table
          ref={componentRef}
          rowclassNameName={(record, index) =>
            index % 2 === 0 ? "" : "table-even-row"
          }
          pagination = {{
            current: tableCurrentPage,
            total: totalNumberOfItems,
            pageSize: PAGE_SIZE,
            onChange: (pageNumber, _) => {
              requestTransactions(pageNumber)
            }
          }}
          size="middle"
          className="table table-hover table-striped"
          bordered
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
        />
      );
    }
  };

  const renderFetching = () => {
    // const { loaderType, loaderColor } = this.state;
    if (loading) {
      return (
        <div className="col-6 offset-sm-3 text-center">
          <p> Fetching your authorized transactions. Please wait...</p>
          <ReactLoading
            className="searching--spinner--100"
            type={loaderType}
            color={loaderColor}
          />
        </div>
      );
    }
  }

  const renderErrorMessage = () => {
    // const { errorMessage, hasError, submitted } = this.state;
    if (!submitted && hasError) {
      return (
        <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          <strong>Error: </strong> {errorMessage}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  }

  return (
    <div className="our-causes pt-0">
      <div className="my-content">
        <div className="row">
          <div className="col-12 ">
            <div className="cause-wrap">
              <div className="cause-content-wrap">
                <div className="entry-content">
                  <div className="col-12">
                    <form
                      className="form-inline"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-group mb-1">
                        <input
                          type="text"
                          className="form-control-plaintext"
                          id="staticEmail2"
                          value="From:"
                          readOnly={true}
                        />
                      </div>
                      <div className="form-group mx-sm-3 mb-2">
                        <DatePicker
                          className="form-control"
                          dateFormat="yyyy/MM/dd"
                          selected={startDate}
                          onChange={handleStartDateChange}
                        />
                      </div>
                      <div className="form-group mb-1">
                        <input
                          type="text"
                          className="form-control-plaintext"
                          id="staticEmail2"
                          value="To:"
                          readOnly={true}
                        />
                      </div>
                      <div className="form-group mx-sm-3 mb-2">
                        <DatePicker
                          className="form-control"
                          dateFormat="yyyy/MM/dd"
                          selected={endDate}
                          onChange={handleEndDateChange}
                        />
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary mb-2 ml-3"
                        onClick={handleSubmit}
                      >
                        Filter{" "}
                      </button>

                      <button
                        className="btn btn-primary mb-2 ml-3"
                        onClick={handleExport}
                      >
                        <Icon type="download" />
                        &nbsp;CSV
                      </button>
                      <ReactToPrint
                        trigger={() => (
                          <button className="btn btn-primary mb-2 ml-3">
                            <Icon type="printer" />
                            &nbsp; Print
                          </button>
                        )}
                        content={() => componentRef.current}
                      />
                    </form>
                  </div>

                  {renderFetching()}
                  {renderTable()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CashoutReport;
